import React, { useRef, useEffect, useState } from 'react';
import {
  AspectRatio,
  Box,
  Heading,
  Flex,
  VStack,
  Show
} from '@chakra-ui/react';
import { height as headerHeight } from '@components/organisms/Header';
import Wrapper from '@components/atoms/Wrapper';
import Image from '@components/atoms/Image';
import ButtonGroup from '@components/molecules/ButtonGroup';
import leftBackdrop from '@assets/images/cadbury-pattern.webp';
import { gsap } from 'gsap';
import { useGSAP } from '@gsap/react';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

if (typeof window !== `undefined`) {
  gsap.registerPlugin(ScrollTrigger);
}

const HomeHero = ({ data: { title, leftImage, image, videoFile, ctas } }) => {
  const videoUrl = videoFile?.url || null;
  const containerRef = useRef(null);
  const titleRef = useRef(null);
  const imageRef = useRef(null);
  const bgRef = useRef(null);
  const [totalTitleHeight, setTotalTitleHeight] = useState(0);

  useEffect(() => {
    const titleHeight = titleRef.current?.clientHeight;
    setTotalTitleHeight(titleHeight);
  }, [titleRef]);

  useGSAP(() => {
    // Ensure element is not null before pushing to the array.
    const staggeredElements = [imageRef.current, bgRef.current].filter(
      (ref) => ref !== null
    );

    // Start Gsap timeline.
    const tl = gsap.timeline({
      scrollTrigger: {
        trigger: containerRef.current
      }
    });
    if (titleRef.current != null) {
      tl.to(
        titleRef.current,
        {
          y: 0,
          opacity: 1
        },
        0.5
      );
    }
    tl.to([staggeredElements], { y: 0, opacity: 1, stagger: 0.5 }, 1);
  }, [containerRef, titleRef, imageRef, bgRef]);

  return (
    <Flex
      as="section"
      position="relative"
      bg="colours.purple"
      height={{ md: `100vh` }}>
      <Flex
        position="relative"
        align="center"
        height="100%"
        maxW={{ md: `50%`, lg: `43%` }}
        pt={headerHeight}
        pb={{ base: 12, md: 28 }}
        backgroundImage={leftBackdrop}
        backgroundPosition="top left"
        backgroundSize="cover"
        backgroundRepeat="repeat">
        <Wrapper
          mx={{ base: `6`, md: 0 }}
          px={{ md: `2.9688rem` }}
          mt={{ base: `3`, md: `36` }}>
          <VStack alignItems="normal" spacing="8" ref={containerRef}>
            {title && (
              <Box overflowY="hidden">
                <Heading
                  ref={titleRef}
                  as="h1"
                  fontSize={{
                    base: `4xl`,
                    lg: `5xl`
                  }}
                  lineHeight="120%"
                  letterSpacing={{ base: `0.2475rem`, md: `0.33rem` }}
                  mb="0"
                  textTransform="uppercase"
                  opacity={0}
                  transform={`translateY(${totalTitleHeight}px)`}
                  willChange="opacity, transform">
                  <Box as="span" display="block">
                    {title}
                  </Box>
                </Heading>
              </Box>
            )}
            {leftImage && (
              <Box ref={imageRef} opacity={0}>
                <AspectRatio ratio={2 / 1}>
                  <Image image={leftImage} loading="lazy" />
                </AspectRatio>
              </Box>
            )}
            {ctas && ctas.length > 0 && (
              <Box ref={bgRef} opacity="0">
                <ButtonGroup
                  ctas={ctas}
                  justify="center"
                  dataLayerType="home_hero"
                  buttonWidth={{ md: `calc(50% - 1rem)` }}
                  mt={{ base: `4`, md: 0 }}
                  size="sm"
                />
              </Box>
            )}
          </VStack>
        </Wrapper>
      </Flex>
      <Show above="md">
        <AspectRatio ratio={1} h="100%" width={{ md: `50%`, lg: `57%` }}>
          <Flex
            flexGrow="1"
            width={{ md: `50%`, lg: `57%` }}
            height={{ md: `100%` }}
            sx={{
              '> .gatsby-image-wrapper': {
                flexGrow: 1
              }
            }}>
            {videoUrl && (
              <Box
                as="video"
                src={videoUrl}
                controls={false}
                autoPlay
                loop
                muted
                playsInline
                objectFit="cover"
                width="100%"
                height="100%"
              />
            )}
            {!videoUrl && image && <Image image={image} loading="eager" />}
          </Flex>
        </AspectRatio>
      </Show>
    </Flex>
  );
};

export default HomeHero;
